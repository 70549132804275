/* eslint-disable no-console */
/**
 * This file is part of [Nainik Base Project]
 *
 * (c) 2021 [Nainik Mehta] <[nainikmehta25@gmail.com]>
 *
 * --------------------------------------------------
 *
 * @module app.v1.askastudent
 * @description Axios setup and its interceptors
 * @author [Nainik Mehta] <[https://www.linkedin.com/in/nainik-mehta-25nk12/]>
 * @version 1.0.0
 *
 * --------------------------------------------------
 */

import axios from 'axios';

import {
  BASE_URL_DEVELOPMENT,
  BASE_URL_PRODUCTION,
} from 'utilities/constants/api';
import { notifyError } from 'utilities/messages/toastMessage';

import { handleLogout } from './reducers/UserState';
import { store } from './store';

// Setting BASE_URL for API
const baseURL =
  process.env.NODE_ENV === 'production'
    ? BASE_URL_PRODUCTION
    : BASE_URL_DEVELOPMENT;

// Creating Axios object
const API = axios.create({
  baseURL,
  responseType: 'json',
});

export default API;

const isDevelopment = process.env.NODE_ENV === 'development';

// Axios Request interceptors
API.interceptors.request.use((req) => {
  const userToken = store.getState().appUserState.token;
  if (userToken)
    req.headers = {
      Authorization: `Bearer ${userToken.replace(/^"|"$/g, '')}`,
    };
  if (isDevelopment) {
    console.log(`[${req.method}] ${req.url}`);
    console.log(`Headers :`);
    console.log(req.headers);
    console.log(`Params : `);
    console.log(req.params);
    console.log(`Data : `);
    console.log(req.data);
  }
  return req;
});

API.interceptors.response.use(
  (res) => {
    const { dispatch } = store;
    const handleError = (code) => {
      notifyError({
        message: res?.data?.errors?.[0]?.messages || res?.data?.message,
      });
      if (code === 401) {
        localStorage.clear();
        dispatch(handleLogout());
        window.location.href = '/';
      }
    };

    if ([400, 404, 500, 403, 401].includes(res?.data?.code)) {
      handleError(res.data.code);
    }

    return res;
  },
  (err) => {
    const { dispatch } = store;
    const handleError = (status) => {
      notifyError({
        message:
          err?.response?.data?.errors[0]?.messages ||
          err?.response?.data?.message,
      });
      if (status === 401) {
        dispatch(handleLogout());
        window.location.href = '/';
      }
    };

    if ([400, 404, 500, 403, 401].includes(err?.response?.status)) {
      handleError(err.response.status);
    } else if (err?.code !== 'ERR_CANCELED')
      notifyError({
        message: 'Internal Server Error! Try again later',
      });

    return err;
  },
);
