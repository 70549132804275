import { App, notification } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import the English locale, replace 'en' with your desired locale
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-quill/dist/quill.snow.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import Spinner from '@core/components/Spinner/Spinner';
import { persistor, store } from 'redux/store';

import './i18n';

// style css
import './App.scss';
import './global-styles.scss';

import reportWebVitals from './reportWebVitals';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);

/* The code `const LazyApp = React.lazy(() => import('./App'));` is using the `React.lazy()` function
to lazily load the `App` component. */
const LazyApp = React.lazy(() => import('./App'));

notification.config({
  placement: 'topRight',
  maxCount: 2,
});

/* The line `const root = ReactDOM.createRoot(document.getElementById('root'));` is creating a root for
the React application. It uses the `createRoot()` method from the `ReactDOM` library to create a new
root instance. The `document.getElementById('root')` part selects the HTML element with the id
'root' where the React application will be rendered. The `root` variable is then used to render the
React component to the DOM using the `root.render()` method. */
const root = ReactDOM.createRoot(document.getElementById('root'));

/* Rendering the React component to the DOM. */
/* Providing the store to the app. */
/* A component that delays the rendering of the app's UI until the persisted state has been
retrieved and saved to redux. */
/* A component that lets you “wait” for something before rendering. */
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App
        message={{
          maxCount: 2,
          duration: 2,
        }}
        notification={{
          placement: 'topRight',
          maxCount: 2,
        }}
      >
        <React.Suspense fallback={<Spinner />}>
          <Router>
            <LazyApp />
          </Router>
        </React.Suspense>
      </App>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
