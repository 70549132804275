import { ReactComponent as LoaderIcon } from '@core/assets/app/loader.svg';
import './Spinner.scss';

function Spinner() {
  return (
    <div className="askastudent-loader">
      <LoaderIcon className="loader-wrapper" />
    </div>
  );
}

export default Spinner;
